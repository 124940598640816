import React, { useEffect, useState } from 'react';
import { useBreakpoints } from 'hooks/useWindowSize';
import { AxiosError } from 'axios';
import { DateRange } from 'api/system/utils/DateRange';
import { WarningIcon } from 'shared/components/icons';
import { LoadingSpinner } from 'shared/components/LoadingSpinner';
import { Banner } from 'shared/components/Banner/Banner';
import { useTheme } from 'styled-components';
import { ChartDataType } from 'modules/types';
import { DateRangeZoomLevel, PowerUnit, SiteTimezoneResponse, SystemType } from 'api/types';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { getSiteTimezone, getSolarPower } from 'api/system';
import { CACHE_STALE_TIME, MonitoringQueryKey, REFETCH_INTERVAL } from 'modules/system/utils/prefetchQueries';
import { AreaChart } from './AreaChart';
import { SpinnerContainer } from '../Chart.styles';
import { DayChartDataProvider } from '../../../utils/ChartDataProviders/DayChartDataProvider';

export function DayChart({ dateRange, system
 }: {dateRange: DateRange, system: SystemType}) {
  const [chartData, setChartData] = useState<ChartDataType[]>([]);
  const [yAxisUnit, setYAxisUnit] = useState<PowerUnit>('W');
  const [dataProvider, setDataProvider] = useState<DayChartDataProvider>(new DayChartDataProvider(system.site_timezone));
  const [largestProductionValue, seLargestProductionValue] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { t } = useTranslation();
  const screenSize = useBreakpoints() || 'md';
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { zoomLevel, startDate } = dateRange;
  const isChartDataEmpty = chartData && chartData.length === 0;

  // if the system hasn't had its timezone set by the backend yet we can grab it here
  useEffect(() => {
    if (!system.site_timezone) {
      const tzQueryKey = [MonitoringQueryKey.TIMEZONE, system.id];
      const cachedTZ: SiteTimezoneResponse | undefined = queryClient.getQueryData(tzQueryKey);
      if (cachedTZ) {
        setDataProvider(new DayChartDataProvider(cachedTZ.timezone))
      } else {
        queryClient.fetchQuery([MonitoringQueryKey.TIMEZONE, system.id],
          () => getSiteTimezone(system.id).then((response) => setDataProvider(new DayChartDataProvider(response.data.timezone)))
        );
      }
    }
  }, [system, queryClient]);

  const solarPowerQuery = useQuery(
    [MonitoringQueryKey.SOLAR_POWER, system?.id, dateRange?.startDate],
    () => getSolarPower({ systemId: String(system?.id), dateRange }).then((res) => res.data),
    {
      enabled:
        Boolean(system?.id) &&
        Boolean(dateRange) &&
        dateRange?.zoomLevel === DateRangeZoomLevel.DAY,
      refetchInterval: REFETCH_INTERVAL,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: CACHE_STALE_TIME,
    },
  );

  const {data, isFetching, isError, error} = solarPowerQuery;

  useEffect(() => {
    if (!isFetching && data && Object.keys(data).length) {
      // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
      const [productionData, _largestProductionValue, _yAxisEnergyUnit] = dataProvider.prepareData(
        data,
        dateRange,
      );
      seLargestProductionValue(_largestProductionValue);
      setChartData(productionData);
      setYAxisUnit(_yAxisEnergyUnit as PowerUnit);
    }
  }, [startDate, zoomLevel, dataProvider, dateRange, isFetching, data]);

  useEffect(() => {
    // don't set errors if query is still loading
    if (!isFetching) {
      if ((isError && (error as AxiosError)?.response?.status === 400) || isChartDataEmpty) {
        setErrorMessage(t('system.monitoring.monitoringUnavailableDateRange'));
      } else if (isError) {
        setErrorMessage(t('system.monitoring.monitoringUnavailable'));
      }
    }
  }, [isError, error, t, isChartDataEmpty, isFetching]);

  return (
    <>
      {(isError || isChartDataEmpty) && errorMessage && (
        <>
          <br />
          <Banner
            bannerText={errorMessage}
            bgColor={theme.colors.bannerWarningYellow20}
            icon={<WarningIcon />}
            dismissable={false}
          />
        </>
      )}
      {!isError &&
        !isChartDataEmpty &&
        (chartData.length && largestProductionValue !== null ? (
          <AreaChart
            data={chartData}
            axes={{
              x: dataProvider.getXAxis(dateRange),
              y: dataProvider.getYAxis(largestProductionValue, yAxisUnit, screenSize),
            }}
            tooltipDateFormatter={dataProvider.tooltipDateFormatter}
            dateRange={dateRange}
            yAxisUnit={yAxisUnit}
          />
        ) : (
          <SpinnerContainer>
            <LoadingSpinner localCenter />
          </SpinnerContainer>
        ))}
    </>
  );
}

export default DayChart;
