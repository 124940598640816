import styled, { css } from 'styled-components';

import { Text } from '../Text/Text';

interface BannerStyledProps {
  $bgColor?: string;
}

export const BannerStyled = styled.div<BannerStyledProps>`
  ${({ theme, $bgColor = theme.colors.cardElevationHighWhite }) => css`
    width: 100%;
    max-width: fit-content;
    display: flex;
    align-items: center;
    background-color: ${$bgColor};
    border-radius: 8px;
    padding: 16px 24px;
    box-shadow: ${theme.elevations.low};
    gap: 8px;
    border: solid 1px ${theme.colors.strokeSecondaryGrey10};
    @media (min-width: ${theme.breakpoints.small}) {
      gap: 12px;
    }
  `}
`;

export const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
`;

export const BannerButton = styled.button`
  border: none;
  background-color: transparent;
  margin: 0 0 0 auto;
  padding: 0;
  align-self: flex-start;
  &:hover {
    cursor: pointer;
  }
`;

export const BannerText = styled(Text)`
  font-family: Avenir;
  font-weight: 800;
`;

export const LinkStyled = styled.a`
  cursor: pointer;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textSystemInfoSecondary2100};
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
`;
