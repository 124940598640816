import { addDays, endOfMonth, endOfYear, startOfMonth, startOfToday, startOfYear } from 'date-fns';
import { DateRangeZoomLevel, SystemType } from 'api/types';
import { QueryClient } from 'react-query';
import {
  getEnergySummary,
  getPowerFlow,
  getSiteTimezone,
  getSolarEnergy,
  getSolarPower,
} from 'api/system';

/**
 * Slight misnomer here, since in context the `queryKey` prop
 * actually refers to the full array provided to react-query.
 */
export enum MonitoringQueryKey {
  TIMEZONE = 'siteTimezone',
  SOLAR_POWER = 'solarPower',
  POWER_FLOW = 'powerFlow',
  ENERGY_SUMMARY = 'solarEnergySummary',
  ENERGY = 'solarEnergy',
  ENERGY_MONTH = 'solarEnergyMonth',
  ENERGY_YEAR = 'solarEnergyYear',
}

export const REFETCH_INTERVAL = 15 * 60 * 1000; // 15 minutes
export const CACHE_STALE_TIME = 15 * 60 * 1000; // cache items are invalidated after 15 minutes, the rate at which ne360 refreshes telemetry

export const prefetchMonitoringQueries = async ({
  queryClient,
  system,
}: {
  queryClient: QueryClient;
  system: SystemType;
}) => {
  const today = startOfToday();

  // set initial date range
  const dateRange = {
    startDate: today,
    endDate: addDays(today, 1),
    zoomLevel: DateRangeZoomLevel.DAY,
  };
  const { id: systemId } = system;

  const prefetchQueries = [
    queryClient.prefetchQuery(
      [MonitoringQueryKey.SOLAR_POWER, systemId, dateRange?.startDate],
      () => getSolarPower({ systemId, dateRange }).then((response) => response.data),
      {
        retry: false,
        staleTime: CACHE_STALE_TIME,
      },
    ),
    queryClient.prefetchQuery([MonitoringQueryKey.POWER_FLOW, systemId], () =>
      getPowerFlow(systemId).then((response) => response.data),
    ),
    queryClient.prefetchQuery([MonitoringQueryKey.ENERGY_SUMMARY, systemId], () =>
      getEnergySummary(systemId).then((response) => response.data),
    ),
    queryClient.prefetchQuery({
      queryKey: [MonitoringQueryKey.ENERGY_MONTH, systemId],
      queryFn: () =>
        getSolarEnergy({
          systemId,
          dateRange: {
            startDate: startOfMonth(today),
            endDate: endOfMonth(today),
            zoomLevel: DateRangeZoomLevel.MONTH,
          },
        }).then((response) => response.data),
      staleTime: CACHE_STALE_TIME,
    }),
    queryClient.prefetchQuery({
      queryKey: [MonitoringQueryKey.ENERGY_YEAR, systemId],
      queryFn: () =>
        getSolarEnergy({
          systemId,
          dateRange: {
            startDate: startOfYear(today),
            endDate: endOfYear(today),
            zoomLevel: DateRangeZoomLevel.YEAR,
          },
        }).then((response) => response.data),
      staleTime: CACHE_STALE_TIME,
    }),
  ];

  // if the system doesn't already have a timezone set, prefetch it here so it's available for the day chart
  if (!system.site_timezone)
    prefetchQueries.push(
      queryClient.prefetchQuery([MonitoringQueryKey.TIMEZONE, systemId], () =>
        getSiteTimezone(String(systemId)).then((res) => res.data),
      ),
    );

  await Promise.all(prefetchQueries);
};
