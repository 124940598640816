import React, { useEffect, useState } from 'react';
import { BrowserRouter, RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import 'legacy/AppDependentRoutes'; // TODO: remove after ff: web refresh 2.1 (auth screens) is removed
import { AxiosInterceptorProvider } from 'contexts/AxiosInterceptorContext';
import GlobalStyles from 'GlobalStyles';
import { UserProvider } from 'contexts/UserContext';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { router } from 'routes/router';
import { ErrorBoundary } from 'shared/components/ErrorBoundary/ErrorBoundary';
import MobileAppBanner from 'legacy/components/MobileAppBanner';
import { darkTheme, lightTheme } from 'shared/design-system/theme/theme';
import { AppDependentRoutes } from 'AppDependentRoutes/AppDependentRoutes';
import { FlagName } from 'api/types';
import { Toast } from 'shared/components/Toast/Toast';
import InContact from 'config/initializers/incontact';

export function App() {
  const [theme, setTheme] = useState<'light' | 'dark'>('light');

  const {
    loading,
    flags: {
      [FlagName.createBrowserRouter]: createBrowserRouter,
      [FlagName.webDarkTheme]: webDarkTheme,
    },
  } = useFeatureFlags();

  useEffect(() => {
    if (webDarkTheme && theme === 'light') {
      setTheme('dark');
    }
  }, [loading, theme, webDarkTheme]);

  if (loading) return null;

  return (
    <>
      <GlobalStyles />
      {createBrowserRouter ? (
        <RouterProvider router={router} />
      ) : (
        <ErrorBoundary>
          <BrowserRouter>
            <MobileAppBanner />
            <AxiosInterceptorProvider>
              <UserProvider>
                <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
                  <Toast />
                  <AppDependentRoutes />
                </ThemeProvider>
              </UserProvider>
            </AxiosInterceptorProvider>
          </BrowserRouter>
          <InContact />
        </ErrorBoundary>
      )}
    </>
  );
}

export default App;
